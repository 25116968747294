import React, { useEffect, useState } from 'react';
import { fetchData } from '../../../utils/api';
import { apiUrl } from '../../../config';
import axios from 'axios';

const RoomAvailability = ({ setShowRoomAvailabilityTable }) => {
    const [roomAvailabilities, setRoomAvailabilities] = useState([]);
    const [editIndex, setEditIndex] = useState(null);
    const [editData, setEditData] = useState({});
    const [newData, setNewData] = useState({
        title: '',
        available_from: '',
        available_to: '',
        type: 'dates',
        available_start: '',
        available_end: ''
    });

    const fetchRoomAvailabilities = async () => {
        const token = localStorage.getItem('accessToken');
        const response = await fetchData(`${apiUrl}/api/sys/get-room-availability`, "GET", token);
        setRoomAvailabilities(response.request.data);
    };
    useEffect(() => {
        fetchRoomAvailabilities();
    }, []);

    const handleEditClick = (index) => {
        setEditIndex(index);
        setEditData({
            ...roomAvailabilities[index],
            available_from: formatDateForInput(roomAvailabilities[index].available_from),
            available_to: formatDateForInput(roomAvailabilities[index].available_to)
        });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditData({ ...editData, [name]: value });
    };

    const handleNewInputChange = (e) => {
        const { name, value } = e.target;
        setNewData({ ...newData, [name]: value });
    };

    const handleSaveClick = async () => {
        try {
            const token = localStorage.getItem('accessToken');
            await axios.post(`${apiUrl}/api/sys/update-room-availability`, editData, {
                headers: { Authorization: `Bearer ${token}` }
            });
            const updatedAvailabilities = [...roomAvailabilities];
            updatedAvailabilities[editIndex] = editData;
            setRoomAvailabilities(updatedAvailabilities);
            setEditIndex(null);
            alert('Доступность обновлена');
        } catch (error) {
            console.error('Ошибка при обновлении доступности:', error);
            alert('Произошла ошибка при обновлении доступности');
        }
    };

    const handleAddClick = async () => {
        try {
            const token = localStorage.getItem('accessToken');
            const response = await axios.post(`${apiUrl}/api/sys/add-room-availability`, newData, {
                headers: { Authorization: `Bearer ${token}` }
            });
            if (response.data.request.status === 0) {
                fetchRoomAvailabilities();
                setNewData({
                    title: '',
                    available_from: '',
                    available_to: '',
                    type: 'dates',
                    available_start: '',
                    available_end: ''
                });
                alert('Доступность добавлена');
            } else {
                alert(response.data.request.message);
            }
        } catch (error) {
            console.error('Ошибка при добавлении доступности:', error);
            alert('Произошла ошибка при добавлении доступности');
        }
    };

    const handleDeleteClick = async (id) => {
        const isConfirmed = window.confirm('Вы уверены, что хотите удалить эту запись?');
        if (isConfirmed) {
            try {
                const token = localStorage.getItem('accessToken');
                await axios.delete(`${apiUrl}/api/sys/del-room-availability?id=${id}`, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                fetchRoomAvailabilities();
                alert('Доступность удалена');
            } catch (error) {
                console.error('Ошибка при удалении доступности:', error);
                alert('Произошла ошибка при удалении доступности');
            }
        }
    };

    const formatDateForInput = (dateString) => {
        const date = new Date(dateString);
        return date.toISOString().split('T')[0];
    };

    return (
        <div className='system-background-hide'>
            <section className="system-container system-container-form system-table-room-availability">
                <div>
                    <h2 className='system-table-room-availability__title'>Доступности номеров</h2>
                    <table>
                        <thead>
                            <tr>
                                <th>Название</th>
                                <th>Дата с</th>
                                <th>Дата до</th>
                                <th>Тип</th>
                                <th>Минимум дней от сегодняшнего дня</th>
                                <th>Максимум дней от сегодняшнего дня</th>
                                <th>Действия</th>
                            </tr>
                        </thead>
                        <tbody>
                            {roomAvailabilities.map((availability, index) => (
                                <tr key={index}>
                                    {editIndex === index ? (
                                        <>
                                            <td><input type="text" name="title" value={editData.title} onChange={handleInputChange} /></td>
                                            <td><input type="date" name="available_from" value={formatDateForInput(editData.available_from)} onChange={handleInputChange} /></td>
                                            <td><input type="date" name="available_to" value={formatDateForInput(editData.available_to)} onChange={handleInputChange} /></td>
                                            <td>
                                                <select name="type" value={editData.type} onChange={handleInputChange}>
                                                    <option value="dates">По датам</option>
                                                    <option value="days">По дням</option>
                                                </select>
                                            </td>
                                            <td><input type="number" name="available_start" value={editData.available_start} onChange={handleInputChange} /></td>
                                            <td><input type="number" name="available_end" value={editData.available_end} onChange={handleInputChange} /></td>
                                            <td className='flex-btns'>
                                                <button className='system-booking-table-btn system-booking-table-btn-view' onClick={handleSaveClick}>Сохранить</button>
                                                <button className='system-booking-table-btn system-booking-table-btn-cancel' onClick={() => setEditIndex(null)}>Отмена</button>
                                            </td>
                                        </>
                                    ) : (
                                        <>
                                            <td>{availability.title}</td>
                                            <td>{formatDateForInput(availability.available_from)}</td>
                                            <td>{formatDateForInput(availability.available_to)}</td>
                                            <td>
                                                {availability.type === 'dates' ? 'По датам' : 'По дням'}
                                            </td>
                                            <td>{availability.available_start}</td>
                                            <td>{availability.available_end}</td>
                                            <td className='flex-btns'>
                                                <button className='system-booking-table-btn system-booking-table-btn-edit' onClick={() => handleEditClick(index)}>Изменить</button>
                                                <button className='system-booking-table-btn system-booking-table-btn-cancel' onClick={() => handleDeleteClick(availability.id)}>Удалить</button>
                                            </td>
                                        </>
                                    )}
                                </tr>
                            ))}
                            <tr>
                                <td><input type="text" name="title" value={newData.title} onChange={handleNewInputChange} /></td>
                                <td><input type="date" name="available_from" value={newData.available_from} onChange={handleNewInputChange} /></td>
                                <td><input type="date" name="available_to" value={newData.available_to} onChange={handleNewInputChange} /></td>
                                <td>
                                    <select name="type" value={newData.type} onChange={handleNewInputChange}>
                                        <option value="dates">По датам</option>
                                        <option value="days">По дням</option>
                                    </select>
                                </td>
                                <td><input type="number" name="available_start" value={newData.available_start} onChange={handleNewInputChange} /></td>
                                <td><input type="number" name="available_end" value={newData.available_end} onChange={handleNewInputChange} /></td>
                                <td className='flex-btns'>
                                    <button className='system-booking-table-btn system-booking-table-btn-view' onClick={handleAddClick}>Добавить</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <button className='system-booking-table-btn system-booking-table-btn-cancel' onClick={() => setShowRoomAvailabilityTable(false)}>Закрыть</button>
                </div>
            </section>
        </div>
    );
};

export default RoomAvailability;
