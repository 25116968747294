import React, { useEffect, useState } from 'react';
import { apiUrl } from '../../../config'
import { fetchData } from '../../../utils/api';
import LanguageChanger from '../../../utils/modules/LanguageChanger';
import { useLanguage } from '../../../LanguageContext';

const RoomEdit = ({ room, onClose }) => {
    const { language } = useLanguage();
    const [messageEditData, setMessageEditData] = useState([])
    const [roomAvailabilities, setRoomAvailabilities] = useState([]);
    const [editRoom, setEditRoom] = useState({
        ...room, // клонируем весь объект room
        translations: {
            ...room.translations, // клонируем translations, если они есть
            additional_info: room.translations?.additional_info || { [language]: '' } // добавляем additional_info, если его нет
        }
    });
    const [newImages, setNewImages] = useState([]);
    useEffect(() => {
        const fetchRoomAvailabilities = async () => {
            const token = localStorage.getItem('accessToken');
            const response = await fetchData(`${apiUrl}/api/sys/get-room-availability`, "GET", token)
            setRoomAvailabilities(response.request.data)
        }
        fetchRoomAvailabilities()
    }, [])
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        // Проверка, если имя поля связано с nested translations (например, translations.room_type.ru)
        if (name === "room_type" || name === "description" || name === "amenities" || name === "additional_info") {
            setEditRoom((prevRoom) => ({
                ...prevRoom,
                translations: {
                    ...prevRoom.translations,
                    [name]: {
                        ...prevRoom.translations[name],
                        [language]: value,  // Обновляем значение для указанного языка
                    },
                },
            }));
        } else {
            // Для других полей обновляем как обычно
            setEditRoom((prevRoom) => ({ ...prevRoom, [name]: value }));
        }
    };
    // Добавление нового удобства
    const handleAddAmenity = () => {
        setEditRoom((prevRoom) => ({
            ...prevRoom,
            translations: {
                ...prevRoom.translations,
                amenities: {
                    ...prevRoom.translations.amenities,
                    [language]: [...(prevRoom.translations.amenities[language] || []), ''], // Добавляем пустую строку для текущего языка
                },
            },
        }));
    };
    const handleAmenityChange = (index, value) => {
        setEditRoom((prevRoom) => {
            const updatedAmenities = [...(prevRoom.translations.amenities[language] || [])]; // Копируем массив удобств для текущего языка
            updatedAmenities[index] = value; // Обновляем элемент по индексу

            return {
                ...prevRoom,
                translations: {
                    ...prevRoom.translations,
                    amenities: {
                        ...prevRoom.translations.amenities,
                        [language]: updatedAmenities, // Обновляем массив для текущего языка
                    },
                },
            };
        });
    };
    const handleRemoveAmenity = (index) => {
        setEditRoom((prevRoom) => {
            const updatedAmenities = (prevRoom.translations.amenities[language] || []).filter((_, i) => i !== index); // Удаляем элемент по индексу
    
            return {
                ...prevRoom,
                translations: {
                    ...prevRoom.translations,
                    amenities: {
                        ...prevRoom.translations.amenities,
                        [language]: updatedAmenities, // Обновляем массив для текущего языка
                    },
                },
            };
        });
    };
    const handleFileChange = (e) => {
        const files = Array.from(e.target.files); // Преобразуем FileList в массив
        if ((files.length + editRoom.images.length) > 5) {
            setMessageEditData(`Можно загрузить максимум 5 фото. Загружено ${editRoom.images.length}`); // Ограничение количества
            e.target.value = ''
            return;
        }

        const filePreviews = files.map((file) => ({
            name: file.name,
            preview: URL.createObjectURL(file),  // Создание временного URL для превью
        }));

        setMessageEditData([]) // Очистка состояния ошибки
        setNewImages(filePreviews)
        setEditRoom({ ...editRoom, images: editRoom.images, new_images: files }); // Сохраняем файлы в состояние
    };
    const sendData = async (roomData) => {
        const formData = new FormData();
        // Добавляем текстовые данные
        for (const key in roomData) {
            if (key !== 'new_images' && key !== 'translations') {
                formData.append(key, roomData[key]);
            }
            if (key === 'translations') {
                formData.append('translations', JSON.stringify(roomData[key]));
            }
        }

        // Добавляем файлы
        if (roomData.new_images) {
            roomData.new_images.forEach((file, index) => {
                formData.append('new_images', file);  // Используем одно и то же имя 'images'
            });
        }
        // Добавляем lang_code
        formData.append('lang_code', language);
        const token = localStorage.getItem('accessToken');
        const data = await fetchData(`${apiUrl}/api/sys/edit-room`, "POST", token, {body: formData}, null)
        setMessageEditData(data.request.message)
        if (data.request.status === 0) {
            setTimeout(() => {
                setMessageEditData([])
            }, 5000)
        } else {
            setTimeout(() => {
                setMessageEditData([])
            }, 25000)
        }
    }

    const delImage = async (id, image) => {
        const isConfirmed = window.confirm('Вы уверены, что хотите удалить эту картинку?');
        if (isConfirmed) {
            try {
                const token = localStorage.getItem('accessToken');
                const data = await fetchData(`${apiUrl}/api/sys/del-room-image?id=${id}&image=${image}`, "DELETE", token)
                setMessageEditData(data.request.message)
                if (data.request.status === 0) {
                    setEditRoom((prevRoom) => {
                        // Фильтруем изображение, которое мы хотим удалить
                        const updatedImages = prevRoom.images.filter((img) => img !== image);
    
                        // Если изображение было удалено, обновляем состояние
                        return {
                            ...prevRoom,
                            images: updatedImages
                        };
                    });
                }
            } catch (error) {
                console.error('Ошибка при удалении:', error);
                alert('Произошла ошибка при удалении картинки');
            }
        }
    }

    return (
        <div className='system-background-hide'>
            <section className="system-container system-container-form">
                <div className='system-container__inner'>
                    <header className="system-header">Редактирование</header>
                    <form className="system-form">
                        <LanguageChanger />
                        <div className="system-input-box">
                            <label htmlFor="status">Показать/скрыть тип номера</label>
                            <select required id="status" value={editRoom.status} name='status' onChange={handleInputChange}>
                                <option value="0">Скрыть</option>
                                <option value="1">Показать</option>
                            </select>
                        </div>
                        <div className="system-input-box">
                            <label>Тип номера</label>
                            <input required placeholder="" value={editRoom.translations.room_type[language] ? editRoom.translations.room_type[language] : '' } type="text" name='room_type' onChange={handleInputChange} />
                        </div>
                        <div className="system-input-box">
                            <label>Общедоступные номера (количество)</label>
                            <input required placeholder="Общедоступные номера" value={editRoom.count} type="number" name='count' onChange={handleInputChange} />
                        </div>
                        <div className="system-input-box">
                            <label>Описание</label>
                            <textarea placeholder="" value={editRoom.translations.description[language] ? editRoom.translations.description[language] : '' } type="text" name='description' onChange={handleInputChange}>
                            </textarea>
                        </div>
                        <div className="system-input-box">
                            <label>Дополнительная информация</label>
                            <textarea placeholder="" value={editRoom.translations.additional_info[language] ? editRoom.translations.additional_info[language] : '' } type="text" name='additional_info' onChange={handleInputChange}>
                            </textarea>
                        </div>
                        <div className="system-input-box">
                            <label>Добавить фото (максимум 5)</label>
                            <input
                                type="file"
                                name="new_images"
                                accept="image/*"
                                onChange={handleFileChange}
                                multiple
                            />
                        </div>
                        <div className="system-input-box">
                            <label>Все номера (количество)</label>
                            <input required placeholder="Все номера" value={editRoom.count_total} type="number" name='count_total' onChange={handleInputChange} />
                        </div>
                        <div className="system-input-box">
                            <label>Цена, летний сезон</label>
                            <input required placeholder="Число" value={editRoom.price_summer} type="number" name='price_summer' onChange={handleInputChange} />
                        </div>
                        <div className="system-input-box">
                            <label>Цена, зимний сезон</label>
                            <input required placeholder="Число" value={editRoom.price_winter} type="number" name='price_winter' onChange={handleInputChange} />
                        </div>
                        <div className="system-input-box">
                            <label>Максимальное количество гостей, которые могут разместиться в номере</label>
                            <input required placeholder="Число" value={editRoom.capacity} type="number" name='capacity' onChange={handleInputChange} />
                        </div>
                        <div className="system-input-box">
                            <label>Количество комнат в номере</label>
                            <input required placeholder="Число" value={editRoom.rooms_count} type="number" name='rooms_count' onChange={handleInputChange} />
                        </div>
                    </form>
                </div>
                    <div className="system-container__inner system-container__inner-images">
                    {editRoom.images.length > 0 && (
                        <>
                        <div className="system-container__inner__images">
                            <header className="system-header">Фотографии</header>
                            {editRoom.images.map((image, index) => (
                                <div className='system-header__edit-image' key={index} onClick={() => delImage(editRoom.id, image)}>
                                    <img 
                                        src={apiUrl + `/api/images/${image}`}
                                        alt={`Room ${index}`}
                                    />
                                </div>
                            ))}
                            </div>
                        {newImages.length ? (
                            <div className="system-container__inner__images">
                                <header className="system-header">Новые</header>
                                {newImages.map((image, index) => (
                                    <div className='system-header__edit-image-new' key={index}>
                                        <img
                                            src={image.preview} 
                                            alt={`Room ${index}`} 
                                        />
                                    </div>
                                ))}
                            </div>
                        ) : null}
                        </>
                    )}
                    <form className="system-form">
                        {/* <div className="system-input-box">
                            <label>Услуги</label>
                            <button type="button" className='system-booking-table-btn system-booking-table-btn-view' onClick={handleAddAmenity}>Добавить услугу</button>
                            {editRoom.translations.amenities[language] ? (
                                editRoom.translations.amenities[language].map((amenity, index) => (
                                    <div key={index} className="amenity-item">
                                        <input
                                            placeholder="Удобство"
                                            value={amenity}
                                            type="text"
                                            onChange={(e) => handleAmenityChange(index, e.target.value)} />
                                        <button type="button" className='system-booking-table-btn system-booking-table-btn-cancel' onClick={() => handleRemoveAmenity(index)}>Удалить</button>
                                    </div>
                                ))
                            ) : null}
                        </div> */}
                        <div className="system-input-box">
                            <label htmlFor="room_availability_id">Тип доступности номера</label>
                            <select required id="room_availability_id" value={editRoom.room_availability_id} name='room_availability_id' onChange={handleInputChange}>
                                <option value={0} disabled>Не выбрано</option>
                                {roomAvailabilities.map((availability, index) => (
                                    <option key={index} value={availability.id}>{availability.title}</option>
                                ))}
                            </select>
                        </div>
                        <div className='flex-btns'>
                            <button type='button' className="system-booking-table-btn system-booking-table-btn-cancel" onClick={onClose}>Закрыть</button>
                            <button type='button' className="system-booking-table-btn system-booking-table-btn-view" onClick={() => sendData(editRoom)}>Сохранить</button>
                        </div>
                        {messageEditData ? (
                            Array.isArray(messageEditData) ? (
                                // Если это массив, рендерим каждый элемент в отдельном span
                                <div className="error-messages">
                                    {messageEditData.map((message, index) => (
                                        <span key={index} className='error-message'>{message}</span>
                                    ))}
                                </div>
                            ) : (
                                // Если это строка, выводим просто одну ошибку
                                <span className='error-message'>{messageEditData}</span>
                            )
                        ) : null}
                    </form>
                </div>
            </section>

        </div>
    );
}

export default RoomEdit;