import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BookingButton from './BookingButton';

function HeaderComponent() {
  // Чтение языка из localStorage, если он сохранён
  const navigate = useNavigate()
  const savedLanguage = localStorage.getItem('language') || 'tm';
  // Состояние для языка
  const [language, setLanguage] = useState(savedLanguage);
  const langs = {
    'ru': {
      'aboutus': "О нас",
      'rooms': "Апартаменты",
      'services': "Услуги",
      'gallery': "Галерея",
      'reviews': "Отзывы",
      'contacts': "Контакты",
      'account': "Личный кабинет",
      'login': "Вход",
      'logout': "Выйти"
    },
    'tm': {
      'aboutus': "Biz hakda",
      'rooms': "Otaglar",
      'services': "Hyzmatlar",
      'gallery': "Galereýa",
      'reviews': "Teswirler",
      'contacts': "Habarlaşmak",
      'account': "Şahsy hasap",
      'login': "Giriş",
      'logout': "Ulgamdan çyk"
    },
    'en': {
      'aboutus': "About us",
      'rooms': "Room",
      'services': "Services",
      'gallery': "Gallery",
      'reviews': "Reviews",
      'contacts': "Contacts",
      'account': "Personal account",
      'login': "Log in",
      'logout': "Log out"
    }
  };

  const handleLogout = () => {
    // Удаляем токен и другие данные из localStorage
    localStorage.removeItem('accessToken');
    localStorage.removeItem('asd');
  
    // Перенаправляем пользователя на страницу входа
    navigate('/authorization');
  
    // Перезагружаем страницу
    window.location.reload();
  };

  // Функция для переключения языка
  const toggleLanguage = () => {
    let newLanguage; // Используем let вместо var
    if (language === 'ru') {
      newLanguage = 'tm';
    } else if (language === 'tm') {
      newLanguage = 'en';
    } else if (language === 'en') {
      newLanguage = 'ru';
    }
    setLanguage(newLanguage);
    localStorage.setItem('language', newLanguage); // Сохраняем язык в localStorage
    window.location.reload();
  };

  const [menuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const token = localStorage.getItem('accessToken')
  const role = localStorage.getItem('asd');

  return (
    <React.Fragment>
      <header className="new-header">
        <div className="wrapper">
          <div className='wrapper__flex'>
            <div className="new-header__logo">
              <a href="/">
                <img src={'/media/img/icons/logo.png'} alt="Logo" />
              </a>
            </div>
            <nav className={`new-header__nav ${menuOpen ? 'active' : ''}`}>
              <ul>
                <li><a className='hover-main-color' href="/aboutus">{langs[language].aboutus}</a></li>
                <li><a className='hover-main-color' href="/rooms">{langs[language].rooms}</a></li>
                <li><a className='hover-main-color' href="/services">{langs[language].services}</a></li>
                <li><a className='hover-main-color' href="/gallery">{langs[language].gallery}</a></li>
                <li><a className='hover-main-color' href="/reviews">{langs[language].reviews}</a></li>
                <li><a className='hover-main-color' href='/contacts'>{langs[language].contacts}</a></li>
                {token && role === "client" ? (
                  <>
                    <li><a href='/books' className='hover-main-color'>{langs[language].account}</a></li>
                    <li><button className='hover-main-color' onClick={handleLogout}>{langs[language].logout}</button></li>
                  </>
                ) : (
                  <li><a className='hover-main-color' href='/authorization'>{langs[language].login}</a></li>
                )}
              </ul>
            </nav>
          </div>
          <button className='lang-switcher' onClick={toggleLanguage}>{language === 'ru' ? 'TM' : language === 'tm' ? 'EN' : 'RU'}</button>
          {!token && role !== "client" && (
            <a className='lang-switcher login-btn-auth' href="/authorization">{langs[language].login}</a>
          )}

          <div className={`menuButton ${menuOpen ? 'active' : ''}`} onClick={toggleMenu}>
              <span className="top"></span>
              <span className="mid"></span>
              <span className="bot"></span>
          </div>
        </div>

        <div className={`new-header__mobile-menu ${menuOpen ? 'active' : ''}`}>
          <nav>
            <ul className='new-header__actions'>
              <li><a className='hover-main-color' href='/aboutus'>{langs[language].aboutus}</a></li>
              <li><a className='hover-main-color' href='/rooms'>{langs[language].rooms}</a></li>
              <li><a className='hover-main-color' href='/services'>{langs[language].services}</a></li>
              <li><a className='hover-main-color' href='/gallery'>{langs[language].gallery}</a></li>
              <li><a className='hover-main-color' href='/reviews'>{langs[language].reviews}</a></li>
              <li><a className='hover-main-color' href='/contacts'>{langs[language].contacts}</a></li>
              {token && role === "client" ? (
                <>
                <li><a className='hover-main-color' href="/books">{langs[language].account}</a></li>
                <li><button className='hover-main-color' onClick={handleLogout}>{langs[language].logout}</button></li>
                </>
              ) : (null)}

            </ul>
          </nav>
        </div>
      </header>
      {token && role === "client" && (
        <BookingButton />
      )}
    </React.Fragment>
  );
}

export default HeaderComponent;
