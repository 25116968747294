export const ru = [
    {
      id: 1,
      image: '/media/img/services/services.jpg',
      images: [
        '/media/img/services/services/1.jpeg',
        '/media/img/services/services/2.jpeg',
        '/media/img/services/services/3.jpeg',
      ],
      title: 'Конференц зал',
      undertitle: 'Кабинеты персональных встреч',
      description: `Большой конференц зал рассчитан на 150 человек. Имеет великолепные акустические параметры, комнаты для переводчиков, удобную мебель. Подойдёт для проведения конференций и других крупных мероприятий.

Кабинеты для персональных встреч рассчитаны на 18-20 человек. Имеют прекрасный интерьер и все необходимое для конфиденциального общения.`,
    },
    {
      id: 2,
      image: '/media/img/services/services2.jpg',
      images: [
        '/media/img/services/services2/1.jpg',
        '/media/img/services/services2/2.jpg',
        '/media/img/services/services2/3.jpg',
      ],
      title: 'Рестораны',
      undertitle: 'В отеле имеется несколько ресторанов',
      description: `Основной ресторан - расположенный на первом этаже рассчитан на 200 человек и предназначен для завтраков, а также для платных обедов и ужинов в формате шведского стола, где Вам предложат большой ассортимент блюд, салатов, закусок, фруктов и выпечки. Помимо этого, по желанию, за отдельную плату Вы можете сделать заказ по меню ресторана.
      
Ресторан расположенный в VIP зоне, имеет 96 посадочных мест. Он привлекает своим уютом и интерьером, и подойдёт для проведения различных торжественных мероприятий и корпоративов.

Ресторан расположенный на 13 этаже, в VIP зоне - является настоящей жемчужиной отеля. Великолепная лаунж зона - рассчитанная на 40 человек с нотками французского интерьера, в виде белого рояля, мягкими креслами, диванами и прекрасной кухней. С возможностью любоваться чудесной панорамой побережья, которая открывается из огромных окон, выходящих на балкон. Здесь, можно сделать предложение любимому человеку, заключить выгодную сделку, провести мероприятия и просто отдохнуть, получив массу впечатлений и положительных эмоций.`,
    },
    {
      id: 3,
      image: '/media/img/services/services3.jpg',
      images: [
        '/media/img/services/services3/1.jpg',
      ],
      title: 'Лобби - бары',
      undertitle: 'В отеле имеется два лобби - бара, расположенных на первом этаже',
      description: `Первый, расположен рядом с основным рестораном, второй, находится в VIP зоне. Оба бара имеют уютный интерьер, располагающий за неторопливой беседой выпить, особенно в зимние вечера, с удовольствием чашечку чая, кофе или горячий шоколад с кусочками великолепного десерта. В жаркое время года - Вам доставит удовольствие большой выбор различных коктейлей и прохладительных напитков.`,
    },
    {
      id: 4,
      image: '/media/img/services/services4.jpg',
      images: [
        '/media/img/services/services4/1.jpg',
        '/media/img/services/services4/2.jpg',
        '/media/img/services/services4/3.jpg',
        '/media/img/services/services4/4.jpg',
        '/media/img/services/services4/5.jpg',
      ],
      title: 'Оздоровительный центр',
      undertitle: 'Медицинские услуги и СПА процедуры',
      description: `Оздоровительный центр - расположенный на первом этаже, представляет собой комплекс современных медицинских услуг и СПА процедур. Здесь, Вы можете привести в тонус своё здоровье и улучшить настроение. Наши специалисты предложат Вам различные виды массажа и физиопроцедуры, также грязевые обёртывания, солевые ванны, скрабирование. После процедур, Вы можете выпить фито-чаи из различных трав, а также попробовать кислородный коктейль, который имеет очень много положительных показаний для улучшения здоровья человека. Данный коктейль насыщает организм кислородом и предотвращает кислородное голодание, благотворно действует на сердечно-сосудистую и дыхательные системы. Способствует налаживанию работы ЖКТ [желчно-кишечного тракта), повышает защитные свойства организма, стрессоустойчивость, улучшает общее самочувствие.`,
    },
    {
      id: 5,
      image: '/media/img/services/services5.jpg',
      images: [
        '/media/img/services/services5/1.jpg',
        '/media/img/services/services5/2.jpg',
        '/media/img/services/services5/3.jpg',
        '/media/img/services/services5/4.jpg',
        '/media/img/services/services5/5.jpg',
        '/media/img/services/services5/6.jpg',
        // '/media/img/services/services5/7.jpg',
        // '/media/img/services/services5/8.jpg',
        '/media/img/services/services5/9.jpg',
        // '/media/img/services/services5/10.jpg',
        '/media/img/services/services5/11.jpg',
        '/media/img/services/services5/12.jpg',
        '/media/img/services/services5/13.jpg',
        // '/media/img/services/services5/14.jpg',
        '/media/img/services/services5/15.jpg',
        '/media/img/services/services5/16.jpg',
      ],
      title: 'Развлекательный центр',
      undertitle: 'Центр с различными видами развлекательных услуг для взрослых и детей', 
      description: `В отеле имеется комплекс развлекательных услуг, в котором каждый гость найдёт развлечение себе по душе. Спектр услуг предусматривает все возрастные категории. Для самых маленьких есть детская комната. Огромное удовольствие доставит посетить в зимнее время крытый аквапарк и бассейн «Волна» с эффектом морской волны. В летний период, на территории работает открытый аквапарк. Имеется боулинг, бильярд, фитнес-зал, игровые автоматы, кинотеатр, караоке, гольф площадка, теннисный корт. Также, многим доставит удовольствие посетить турецкий хаммам и сауну. В VIP зоне расположен отдельный СПА с бассейном, турецкой баней.`,
    }
  ];
export const tm = [
    {
      id: 1,
      image: '/media/img/services/services.jpg',
      images: [
        '/media/img/services/services/1.jpeg',
        '/media/img/services/services/2.jpeg',
        '/media/img/services/services/3.jpeg',
      ],
      title: 'Mejlisler zaly',
      undertitle: `Şahsy ýygnak otaglary`,
      description: `Uly maslahat zaly 150 adama niýetlenendir. Ajaýyp akustiki parametrleri, terjimeçiler üçin otaglar we oňaýly mebel bar. Maslahatlar we beýleki uly çäreleri geçirmek üçin oňaýlydyr.

Ikitaraplaýyn gepleşikler otagy 18-20 adam üçin niýetlenendir. Ajaýyp içerki bezegi we gizlin gepleşikler üçin zerur ähli amatlyklary bar.`,
    },
    {
      id: 2,
      image: '/media/img/services/services2.jpg',
      images: [
        '/media/img/services/services2/1.jpg',
        '/media/img/services/services2/2.jpg',
        '/media/img/services/services2/3.jpg',
      ],
      title: 'Restoranlar',
      undertitle: 'Myhmanhanada birnäçe restoran bar',
      description: `Esasy restoran - birinji gatda ýerleşýär 200 orunlyk we ertirlik üçin, şeýle hem şwed stoly görnüşindäki tölegli günortanlyk we agşamlyk naharlary üçin
niýetlenendir, bu ýerde size köp sanly tagam, salat, sowuk nahar, miwe we hamyr önümleri hödürlener. Mundan başga-da, isleseňiz, goşmaça töleg bilen restoran menýusyndan sargyt edip bilersiňiz.

VIP zolagynda ýerleşyan restoran 96 orunlykdyr. Ol öz rahatlygy we içerki bezegi bilen özüne çekýär we dürli baýramçylyk çäreleri we oturylyşyklar üçin amatlydyr.

VIP zolagynda 13-nji gatda ýerleşýän restoran bolsa myhmanhananyň hakyky göwher gaşydyr. Ajaýyp dynç alyş (launj) zolagy - ak roýal, ýumşak oturgyçlar, diwanlar we ajaýyp aşhana görnüşinde fransuz içerki bezegleri bilen 40
adam üçin niýetlenendir. Balkona çykýan ullakan penjirelerden açylýan kenar ýakasynyň täsin görnüşinden lezzet almak mümkinçiligi hem bar. Bu ýerde,
söygüli adamyňyza teklip edip, peýdaly şertnama baglaşyp, çäreleri geçirip we
diňe dynç alyp, köp täsirleri we oňyn duýgulary alyp bilersiňiz.`,
    },
    {
      "id": 3,
      "image": "/media/img/services/services3.jpg",
      images: [
        '/media/img/services/services3/1.jpg',
      ],
      "title": "Lobbi - barlar",
      undertitle: 'Myhmanhananyň birinji gatynda ýerleşýän iki sany lobbi-bar bardyr.',
      "description": `Birinjisi esasy restoranyň gapdalynda, ikinjisi VIP zolakda ýerleşýär. Iki baryň hem rahat içerki bezegi bar, esasanam gyş gijelerinde, bir käse çaý, kofe ya-da gyzgyn şokolad bilen ýakymly desert bölejikleri bilen sizi howlukman söhbet etmäge çagarýar. Ýylyň yssy möwsümlerinde dürli kokteýllerden we sowuk içgilerden lezzet alarsyňyz.`
    },
    {
      "id": 4,
      "image": "/media/img/services/services4.jpg",
      images: [
        '/media/img/services/services4/1.jpg',
        '/media/img/services/services4/2.jpg',
        '/media/img/services/services4/3.jpg',
        '/media/img/services/services4/4.jpg',
        '/media/img/services/services4/5.jpg',
      ],
      "title": "Sagaldyş merkezi",
      undertitle: 'Lukmançylyk hyzmatlary we SPA proseduralary',
      "description": `Birinji gatda ýerleşýän sagaldyş merkezi häzirki zaman lukmançylyk hyzmatlaryny we SPA proseduralaryň toplumyny öz içine alýar. Siz bu ýerde öz saglygyňy tonusa getirip, keýpiňizi gowulaşdyryp bilersiňiz. Biziň hünarmenlerimiz size dürli massaž görnüşlerini we fizioproseduralaryny, şeyle hem palçyga dolanmak, duz wannalary we skrablamagy hödürlar. Proseduralardan soň, dürli otlardan ösümlik çaýlaryny (fito-çaý) içip bilersiňiz, şeyle hem adam saglygyny gowulaşdyrmak üçin köp oňyn görkezijileri bolan kislorod kokteýlini dadyp bilersiňiz. Bu kokteýl bedeni kislorod bilen doldurýar we kislorod ýetmezçiliginiň öňüni alýar, yürek-damar we dem alyş ulgamlaryna peýdaly täsir edýär. Öd-içege ulgamynyň işini kadalaşdyrmaga kömek edýär, bedeniň gorag häsiýetlerini, streslere garşylygy yokarlandyrýar we umumy özüňi duýuşy gowulaşdyrýar.`
    },
    {
      "id": 5,
      "image": "/media/img/services/services5.jpg",
      images: [
        '/media/img/services/services5/1.jpg',
        '/media/img/services/services5/2.jpg',
        '/media/img/services/services5/3.jpg',
        '/media/img/services/services5/4.jpg',
        '/media/img/services/services5/5.jpg',
        '/media/img/services/services5/6.jpg',
        // '/media/img/services/services5/7.jpg',
        // '/media/img/services/services5/8.jpg',
        '/media/img/services/services5/9.jpg',
        // '/media/img/services/services5/10.jpg',
        '/media/img/services/services5/11.jpg',
        '/media/img/services/services5/12.jpg',
        '/media/img/services/services5/13.jpg',
        // '/media/img/services/services5/14.jpg',
        '/media/img/services/services5/15.jpg',
        '/media/img/services/services5/16.jpg',
      ],
      "title": "Dynç alyş merkezi",
      undertitle: 'Ulular we çagalar üçin dürli görnüşli güýmenje hyzmatlary merkezi',
      "description": `Myhmanhanada her bir myhmanyň göwnünden turjak güýmenjesini tapjak dürli güýmenje hyzmatlarynyň toplumy bar. Hyzmatlaryň görnüşi ähli ýaş derejelerini öz içine alýar. Iň kiçiler üçin çagalar otagy bar. Gyşda deňiz tolkun täsiri bolan ýapyk akwapark we "Tolkun" howzuna baryp görmek gaty ýakymly bolar. Tomus möwsüminde çäkde açyk akwapark işleýar. Bowling, bilýard, fitnes zal, oýun awtomatlary, kinoteatr, karaoke, golf meýdançasy, tennis korty bar. Şeyle hem, köp adamlara türk hammamyna we sauna baryp görmek lezzet berer. VIP zolagynda howuzly we türk hammamly aýratyn SPA merkezi ýerleşýär.`
    }
  ];
export const en = [
    {
      id: 1,
      image: '/media/img/services/services.jpg',
      images: [
        '/media/img/services/services/1.jpeg',
        '/media/img/services/services/2.jpeg',
        '/media/img/services/services/3.jpeg',
      ],
      title: 'Conference hall',
      undertitle: 'Personal meeting rooms',
      description: `The large conference hall is designed for 150 people. It has excellent acoustic parameters, rooms for interpreters, comfortable furniture. It is suitable for conferences and other large events.
      
The private meeting rooms are designed for 18-20 people. They have a beautiful interior and everything necessary for confidential communication.`,
    },
    {
      id: 2,
      image: '/media/img/services/services2.jpg',
      images: [
        '/media/img/services/services2/1.jpg',
        '/media/img/services/services2/2.jpg',
        '/media/img/services/services2/3.jpg',
      ],
      title: 'Restaurants',
      undertitle: 'The hotel has several restaurants',
      description: `The main restaurant - located on the first floor, can accommodate 200 people and is intended for breakfasts, as well as paid lunches and dinners in the buffet format, where you will be offered a wide range of dishes, salads, snacks, fruits and pastries. In addition, if desired, for an additional fee, you can order from the restaurant menu.
      
The restaurant which is located in the VIP zone, has 96 seats. It attracts with its coziness and interior, and is suitable for holding various special events and corporate parties.

The restaurant which is located on the 13th floor, in the VIP zone - is a real pearl of the hotel. A magnificent lounge area - designed for 40 people with notes of French interior, in the form of a white piano, soft armchairs, sofas and excellent cuisine. With the opportunity to admire the wonderful panorama of the coast, which opens from the huge windows overlooking the balcony. Here, you can propose to your loved one, make a profitable deal, hold events and just relax, getting a lot of impressions and positive emotions.`,
    },
    {
      id: 3,
      image: '/media/img/services/services3.jpg',
      images: [
        '/media/img/services/services3/1.jpg',
      ],
      title: 'Lobby bars',
      undertitle: 'The hotel has two lobby bars, located on the first floor',
      description: `The first is located next to the main restaurant, the second is in the VIP zone. Both bars have a cozy interior, inviting you to have a leisurely drink, especially on winter evenings, with pleasure a cup of tea, coffee or hot chocolate with pieces of a magnificent dessert. In the summer season - you will enjoy a large selection of various cocktails and soft drinks.`,
    },
    {
      id: 4,
      image: '/media/img/services/services4.jpg',
      images: [
        '/media/img/services/services4/1.jpg',
        '/media/img/services/services4/2.jpg',
        '/media/img/services/services4/3.jpg',
        '/media/img/services/services4/4.jpg',
        '/media/img/services/services4/5.jpg',
      ],
      title: 'Recreation center',
      undertitle: 'Medical services and SPA procedures',
      description: `The recreation center - located on the first floor, is a complex of modern medical services and SPA procedures. Here, you can tone up your health and improve your mood. Our specialists will offer you various types of massage and physiotherapy, as well as mud wraps, salt baths, scrubbing. After the procedures, you can drink herbal teas from various herbs, and also try an oxygen cocktail, which has many positive indications for improving human health. This cocktail saturates the body with oxygen and prevents oxygen starvation, has a beneficial effect on the cardiovascular and respiratory systems. It helps to improve the functioning of the gastrointestinal tract (biliary tract), increases the body's defenses, stress resistance, improves overall well-being.`,
    },
    {
      id: 5,
      image: '/media/img/services/services5.jpg',
      images: [
        '/media/img/services/services5/1.jpg',
        '/media/img/services/services5/2.jpg',
        '/media/img/services/services5/3.jpg',
        '/media/img/services/services5/4.jpg',
        '/media/img/services/services5/5.jpg',
        '/media/img/services/services5/6.jpg',
        // '/media/img/services/services5/7.jpg',
        // '/media/img/services/services5/8.jpg',
        '/media/img/services/services5/9.jpg',
        // '/media/img/services/services5/10.jpg',
        '/media/img/services/services5/11.jpg',
        '/media/img/services/services5/12.jpg',
        '/media/img/services/services5/13.jpg',
        // '/media/img/services/services5/14.jpg',
        '/media/img/services/services5/15.jpg',
        '/media/img/services/services5/16.jpg',
      ],
      title: 'Entertainment center',
      undertitle: 'A center with various types of entertainment services for adults and children',
      description: `The hotel has a complex of entertainment services, where every guest will find entertainment to their liking. The range of services provides for all age categories. For the little ones, there is a children's room. It will be a great pleasure to visit the indoor water park and the "Volna(wave)" pool with the effect of a sea wave in winter. In the summer, an outdoor water park is open on the territory. There is a bowling alley, billiards, a fitness room, slot machines, a cinema, karaoke, a golf course, a tennis court. Also, many will enjoy visiting the Turkish hammam(bath) and sauna. In the VIP zone there is a separate SPA with a pool and a Turkish bath.`,
    }
  ];