import React from 'react';
import '../styles/css/index.css';

import HeaderComponent from '../components/HeaderComponent';
import FooterComponent from '../components/FooterComponent';

import ServicesComponent from '../components/ServicesComponent';


function Services() {
    return (
        <React.Fragment>
            <HeaderComponent />
              <ServicesComponent />
            <FooterComponent />
        </React.Fragment>
    );
}

export default Services;
