import React, { useEffect, useState } from 'react';
import { intervalUpdatingData } from '../../utils/dynamic';

import { fetchData } from '../../utils/api';
import { apiUrl } from '../../config'
import RoomView from '../../components/system/rooms/RoomView';
import RoomEdit from '../../components/system/rooms/RoomEdit';
import RoomNew from '../../components/system/rooms/RoomNew';
import axios from 'axios';
import RoomAvailability from '../../components/system/rooms/RoomAvailability';

const Rooms = ({ setHeaderTitle }) => {
    React.useEffect(() => {
      setHeaderTitle('Номера');
    }, [setHeaderTitle]);
    const [rooms, setRooms] = useState([])
    const [roomsMessage, setRoomsMessage] = useState([])
    const [loading, setLoading] = useState(false); // состояние для отслеживания загрузки
    const [showRoomAvailabilityTable, setShowRoomAvailabilityTable] = useState(false); // состояние для показа таблицы доступности номеров
    const [isEditOpen, setIsEditOpen] = useState(false); // состояние для показа формы редактирования записи
    const [isViewOpen, setIsViewOpen] = useState(false); // состояние для просмотра записи
    const [isNewOpen, setIsNewOpen] = useState(false); // состояние для создания новой записи записи
    const [editData, setEditData] = useState([]); // состояние с данными для редактирования записи
    const [viewData, setViewData] = useState([]); // состояние с данными для просмотра записи

    const delRequest = async (room) => {
      const token = localStorage.getItem('accessToken');
      const isConfirmed = window.confirm('Вы уверены, что хотите удалить эту запись?');
      if (isConfirmed) {
        try {
          const room_id = room.id
          const response = await axios.delete(`${apiUrl}/api/sys/del-room?id=${room_id}`, { headers: {Authorization: `Bearer ${token}`} })
          // значение об удалении
          alert(response.data.request.message);
        } catch (error) {
          console.error('Ошибка при удалении:', error);
          alert('Произошла ошибка при удалении');
        }
      }
      
    }

    // Handle VIEW/EDIT/DELETE click
    const handleVEDNClick = (room, action) => {
        switch (action) {
          case 'view':
            setViewData(room)
            setIsViewOpen(true);
            break;
          case 'edit':
            setEditData(room)
            setIsEditOpen(true);
            break;
          case 'new':
            setIsNewOpen(true);
            break;
          case 'del':
            delRequest(room);
            break;
          default:
            break
        }
    };
    // Handle VIEW/EDIT closer
    const handleVEClose = () => {
        setIsViewOpen(false);
        setIsEditOpen(false);
        setIsNewOpen(false);
    };

    const fetchRooms = async () => {
        try {
          const token = localStorage.getItem('accessToken');
          const data = await fetchData(`${apiUrl}/api/sys/get-rooms`, "GET", token)
          if (data.request.status === 0) {
              // Обработка данных и связывание booking с соответствующим room
              setRooms(data.request.data);
              setLoading(false); // Останавливаем загрузку
        } else {
            setRoomsMessage(data.request.message);
        }
        } catch (error) {
            console.error("Ошибка:", error);
        }
    };

    const handleDragStart = (e, index) => {
      e.dataTransfer.setData("roomIndex", index);
    };

    const handleDrop = async (e, index) => {
      const draggedRoomIndex = parseInt(e.dataTransfer.getData("roomIndex"), 10);
      if (draggedRoomIndex === index) return;

      const updatedRooms = Array.from(rooms);
      const [draggedRoom] = updatedRooms.splice(draggedRoomIndex, 1);
      updatedRooms.splice(index, 0, draggedRoom);
      setRooms(updatedRooms);

      try {
        const token = localStorage.getItem('accessToken');
        await axios.post(`${apiUrl}/api/sys/update-room-priority`, 
          { rooms: updatedRooms.map((room, idx) => ({ id: room.id, priority: idx + 1 })) },
          { headers: { Authorization: `Bearer ${token}` } }
        );
      } catch (error) {
        console.error('Ошибка при обновлении порядка:', error);
        alert('Произошла ошибка при обновлении порядка');
        // Revert the order change
        const revertedRooms = Array.from(rooms);
        const [revertedRoom] = revertedRooms.splice(index, 1);
        revertedRooms.splice(draggedRoomIndex, 0, revertedRoom);
        setRooms(revertedRooms);
      }
    };

  useEffect(() => {
    fetchRooms();
    // Обновление данных каждые 10 секунд
    const interval = setInterval(() => {
      setLoading(true)
      setTimeout(() => {
        fetchRooms();
      }, 3000)
    }, intervalUpdatingData);
    // Очистка интервала при размонтировании компонента
    return () => clearInterval(interval);
  }, []);

  return (
      <>
      {showRoomAvailabilityTable && (<RoomAvailability setShowRoomAvailabilityTable={setShowRoomAvailabilityTable} />)}
        <ul className='block-message'>
          <li>Помеченные красным скрыты из общего доступа</li>
        </ul>
        <section className="system-bookings-table">
          <div className='d-flex just-content-sb'>
            <div className='d-flex'>
              <button className="system-booking-table-btn system-booking-table-btn-view" onClick={() => handleVEDNClick(null, 'new')}>+</button>
              <button className="system-booking-table-btn system-booking-table-btn-view" onClick={() => (setShowRoomAvailabilityTable(true))}>Типы доступности номеров</button>
            </div>
            {loading && (
              <div className="loader">
                <div className="circle"></div>
                <div className="circle"></div>
                <div className="circle"></div>
                <div className="circle"></div>
              </div>
            )}
          </div>

          <table className='system-table'>
            <thead className='system-thead'>
              <tr className='system-tr'>
                <th className='system-th'>№ порядок</th>
                <th className='system-th'>Тип номера</th>
                <th className='system-th'>Общедоступные номера</th>
                <th className='system-th'>Все номера</th>
                <th className='system-th'>Цена, летний сезон</th>
                <th className='system-th'>Цена, зимний сезон</th>
                <th className='system-th'>Макс. количество гостей</th>
                <th className='system-th'>Количество комнат в номере</th>
                <th className='system-th'>Действия</th>
              </tr>
            </thead>
            <tbody className='system-tbody'>
            {rooms && rooms.length > 0 ? (
              <React.Fragment>
              {rooms.map((room, index) => (
                <tr className={`system-tr${(room.status === 0) && ' red-background'}`} key={index + 1} onDrop={(e) => handleDrop(e, index)} onDragOver={(e) => e.preventDefault()}>
                  <td className="system-td" draggable onDragStart={(e) => handleDragStart(e, index)}>{index + 1} &#x2630;</td>
                  <td className="system-td">{room.translations.room_type.ru}</td>
                  <td className="system-td">{room.count}</td>
                  <td className="system-td">{room.count_total}</td>
                  <td className="system-td">{room.price_summer}</td>
                  <td className="system-td">{room.price_winter}</td>
                  <td className="system-td">{room.capacity}</td>
                  <td className="system-td">{room.rooms_count}</td>
                  <td className="system-td">
                    <button className="system-booking-table-btn system-booking-table-btn-view" onClick={() => handleVEDNClick(room, 'view')}>Посмотреть</button>
                    <button className="system-booking-table-btn system-booking-table-btn-view" onClick={() => handleVEDNClick(room, 'edit')}>Изменить</button>
                    <button className="system-booking-table-btn system-booking-table-btn-cancel" onClick={() => handleVEDNClick(room, 'del')}>Удалить</button>
                  </td>
                </tr>
              ))}
              </React.Fragment>
            ) : (
              <tr>
                <td className="system-td">{roomsMessage}</td>
              </tr>
            )}
            </tbody>
          </table>
        </section>
        {isViewOpen && (
          <RoomView room={viewData} onClose={handleVEClose} />
        )}
        {isEditOpen && (
          <RoomEdit room={editData} onClose={handleVEClose} />
        )}
        {isNewOpen && (
          <RoomNew onClose={handleVEClose} />
        )}
      </>
  );
};

export default Rooms;