import React from 'react';

import { boolElemByRole } from '../../utils/dynamic';
import { useNavigate } from 'react-router-dom';
function SideBar() {
  const navigate = useNavigate()
  const handleLogout = () => {
    // Удаляем токен и другие данные из localStorage
    localStorage.removeItem('accessToken');
    localStorage.removeItem('asd');
  
    // Перенаправляем пользователя на страницу входа
    navigate('/login');
  
    // Перезагружаем страницу
    window.location.reload();
  };

  return (
    <div className="system-sidebar">
      <div className="logo">HotelCMS</div>
      <nav>
        <ul>
          {boolElemByRole(['admin']) && (
            <>
            <div className="logo">Администрация</div>
            {/* <li><a href="/system/users">Пользователи</a></li> */}
            {/* <li><a href="/system/nopaybookings">Не оплачение бронирования</a></li> */}
            <li><a href="/system/customers">Клиенты</a></li>
            </>

          )}
          {boolElemByRole(['admin', 'booker']) && (
            <>
            <li><a href="/system/reviews">Отзывы</a></li>
            <li><a href="/system/rooms">Номера</a></li>
            {/* <li><a href="/system/rooms/booking-pms">Таблица бронирований</a></li> */}
            <div className="logo">Онлайн брон.</div>
            <li><a href="/system">Активные брон.</a></li>
            <li><a href="/system/expired-bookings">Просроченные</a></li>
            <li><a href="/system/canceled-bookings">Отмененные</a></li>
            <div className="logo">Ручные брон.</div>
            <li><a href="/system/off">Активные брон.</a></li>
            <li><a href="/system/off/expired-bookings">Просроченные</a></li>
            <li><a href="/system/off/canceled-bookings">Отмененные</a></li>
            </>
          )}
          {boolElemByRole(['admin', 'reception']) && (
            <>
            <div className="logo">Ресепшн</div>
            <li><a href="/system/reception">Брони</a></li>
            <li><a href="/system/reception/confirmes">Подтвержденные</a></li>
            <li><a href="/system/reception/canceled-bookings">Отмененные</a></li>
            </>
          )}
        </ul>
        <button className='system-booking-table-btn system-booking-table-btn-cancel' onClick={handleLogout}>Выйти</button>

      </nav>
    </div>
  );
}

export default SideBar;
