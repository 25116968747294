import React, { useEffect } from "react";
import { apiUrl } from "../../config";
import { useNavigate } from "react-router-dom";
import { useLanguage } from "../../LanguageContext";
import { getNumberOfDays } from "../../utils/dynamic";


const RoomCard = ({typePage, room, checkIn=null, checkOut=null, bookingPrice, periodStatus = false }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if ((typePage === 'book' && !checkIn) || (typePage === 'book' && !checkOut)) {
      navigate('/')
    }
  })
    const { language } = useLanguage();
    // Состояние для языка
    const langs = {
      'ru': {
        'night': "День, ночь",
        'more_btn': "Детали",
        'available_rooms_title': "Кол. свободных",
        'max_guests_title': "Гостей",
        'rooms_title': "Комнат",
        'full_period': "За весь период",
      },
      'tm': {
        'night': "Gündiz gije",
        'more_btn': "Maglumat",
        'available_rooms_title': "Bohçalaryň sany",
        'max_guests_title': "Myhmanlaryň sany",
        'rooms_title': "Otag sany",
        'full_period': "Doly möhlet üçin baha",
      },
      'en': {
        'night': "Night",
        'more_btn': "More",
        'available_rooms_title': "Available apartments",
        'max_guests_title': "Guests",
        'rooms_title': "Rooms",
        'full_period': "For full period",
      }
    };
    return (
        <div className="rooms-page-card">
            <img src={`${apiUrl}/api/images/${room.images[0]}`} alt={room.translations.room_type} className="rooms-page__image" />
            <div className="rooms-page__details">
                <h3 className="rooms-page__name">{room.translations.room_type}</h3>
                {/* <p className="rooms-page__item">{langs[language].available_rooms_title}: {room.available_rooms}</p> */}
                <p className="rooms-page__item">{langs[language].max_guests_title}: {room.capacity}</p>
                <p className="rooms-page__item">{langs[language].rooms_title}: {room.rooms_count}</p>
                <div className="rooms-page__prices">
                  <div className="rooms-page__price-container">
                    <span className="rooms-page__price-old">
                        {`TMT ${(periodStatus) ? room.price_summer * getNumberOfDays(checkIn, checkOut) : room.price_summer}`}
                    </span>
                    <span className="rooms-page__price-discounted">
                        {`TMT ${(periodStatus) ? room.price * getNumberOfDays(checkIn, checkOut) : room.price}`} 
                    </span>
                    <span className="rooms-page__discount-badge">
                        -20%
                    </span>
                    <span className="rooms-page__price-per-night">
                        / {(!periodStatus) ? langs[language].night : langs[language].full_period}
                    </span>
                  </div>
                </div>
                {typePage === 'book' ? (
                  <a href={`/rooms/${room.id}/book?checkIn=${checkIn}&checkOut=${checkOut}&periodStatus=${periodStatus}`}
                      className="button-zj pulse-button">{langs[language].more_btn}</a>
                ) : (
                  <a href={`/rooms/${room.id}/view`}
                      className="button-zj">{langs[language].more_btn}</a>
                )}
            </div>
        </div>
  );
};

export default RoomCard;
