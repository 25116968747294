import React from 'react';
import '../styles/css/index.css';

import HeaderComponent from '../components/HeaderComponent';
import FooterComponent from '../components/FooterComponent';

import { ru, en, tm } from '../components/objects/services';
import { useParams } from 'react-router-dom';

function Service() {
    const { id } = useParams();
    const idV = parseInt(id, 10); // Преобразуем в число
    const savedLanguage = localStorage.getItem('language') || 'tm';
    // Выбираем массив с данными в зависимости от языка
    const services = savedLanguage === 'ru' ? ru : savedLanguage === 'en' ? en : tm;
    const service = services.find((s) => s.id === idV);

    if (!service) {
        return <p>Услуга не найдена</p>;
    }

    return (
        <React.Fragment>
            <HeaderComponent />
            <div className="service-page">
                <h1 className='title'>{service.title}</h1>
                <p className='under-line undertitle'>{service.undertitle}</p>
                <div className="service-image service-main-image">
                    <img src={service.image} alt={`service-${service.id}`} />
                </div>
                <div className="service-description">
                    <p>{service.description}</p>
                </div>
                <div className="service-gallery">
                    {service.images && service.images.map((img, index) => (
                        <div className="service-image" key={index}>
                            <img src={img} alt={`service-${service.id}-${index}`} />
                        </div>
                    ))}
                </div>
            </div>
            <FooterComponent />
        </React.Fragment>
    );
}

export default Service;
