import React, { useEffect, useState } from 'react';

import HeaderComponent from '../components/HeaderComponent';
import FooterComponent from '../components/FooterComponent';
import RoomCard from '../components/RoomsPage/RoomCard';

import { fetchData } from '../utils/api'
import { apiUrl } from '../config'
import { useLanguage } from '../LanguageContext';
import { useLocation } from 'react-router-dom';


function BookRooms() {
    const { language } = useLanguage();
    const location = useLocation();
    const [bookingPrice, setBookingPrice] = useState(0);

    // Состояние для языка
    const langs = {
        'ru': {
            'title': "Апартаменты",
            'message': "Нет свободных номеров на период выбранных дат."
        },
        'tm': {
            'title': "Otaglar",
            'message': "Saýlanan seneler üçin otag ýok."
        },
        'en': {
            'title': "Apartments",
            'message': "There are no available rooms for the selected dates."
        }
    };
    const [searchResults, setSearchResults] = useState(location.state?.searchResults?.request?.data || null);
    const [searchResultsMessage, setSearchResultsMessage] = useState(location.state?.searchResults?.request?.message || langs[language].message);
    const [maxPeriodForBooking, setMaxPeriodForBooking] = useState(null);
    
    
    
    // Обновляем результаты при каждом новом состоянии
    useEffect(() => {
      const validMessages = (error_id) => {
          switch (error_id) {
            case 0:
              if (language === 'tm') {
                  return "Üstünlik."
              }
              if (language === 'ru') {
                  return 'Успешный поиск.'
              }
              if (language === 'en') {
                  return 'Success.'
              }
              break;
            case 1:
              if (language === 'tm') {
                return 'Извините, на данный момент нет доступных номеров.'
              }
              if (language === 'ru') {
                return 'Извините, на данный момент нет доступных номеров.'
              }
              if (language === 'en') {
                return 'Извините, на данный момент нет доступных номеров.'
              }
              break;
            case 12:
              if (language === 'tm') {
                return 'Дата выезда должна быть после даты заезда.'
              }
              if (language === 'ru') {
                return 'Дата выезда должна быть после даты заезда.'
              }
              if (language === 'en') {
                return 'Дата выезда должна быть после даты заезда.'
              }
              break;
            case 13:
              if (language === 'tm') {
                return `Максимальный период бронирования ${maxPeriodForBooking} дней.`
              }
              if (language === 'ru') {
                return `Максимальный период бронирования ${maxPeriodForBooking} дней.`
              }
              if (language === 'en') {
                return `Максимальный период бронирования ${maxPeriodForBooking} дней.`
              }
              break;
          }
      }

      
      if (location.state?.searchResults) {
        if (location.state.searchResults.request.status === 0) {
          setSearchResults(location.state.searchResults.request.data);
          setSearchResultsMessage(validMessages(location.state.searchResults.request.status))
        } else {
          if (location.state.searchResults.request.status === 13) {
            setMaxPeriodForBooking(location.state.searchResults.request.maxPeriodForBooking)
            if (maxPeriodForBooking) {
              alert(validMessages(location.state.searchResults.request.status))
            }
          } else {
            alert(validMessages(location.state.searchResults.request.status))
          }
        }
      }
    }, [location.state, maxPeriodForBooking, language]);

  return (
      <React.Fragment>
          <HeaderComponent />
          <h1 className='rooms-title'>{langs[language].title}</h1>
            <p className='rooms-page-list-message'>
                {searchResultsMessage}:
                {searchResults?.checkIn && searchResults?.checkOut &&
                    " " + searchResults.checkIn + " - " + searchResults.checkOut
                }
            </p>
          <div className="rooms-page-list container">
                {searchResults && searchResults.map((room, index) => (
                    <RoomCard
                        key={index}
                        typePage={'book'}
                        room={room}
                        checkIn={searchResults.checkIn}
                        checkOut={searchResults.checkOut}
                        bookingPrice={bookingPrice}
                        periodStatus={true}
                    />
                ))}
          </div>
      </React.Fragment>
  );
}

export default BookRooms;
