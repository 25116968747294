import React from 'react';
import { ru, en, tm } from '../components/objects/services'
const ServicesComponent = () => {
  const savedLanguage = localStorage.getItem('language') || 'tm';
  // Состояние для языка
  const langs = {
    'ru': {
      'title': "Услуги",
      'services': ru,
      'more_btn': "Детали",
    },
    'tm': {
      'title': "Hyzmatlar",
      'services': tm,
      'more_btn': "Maglumat",
    },
    'en': {
      'title': "Services",
      'services': en,
      'more_btn': "More",
    }
  };


  return (
    <section className="services container">
      <h1 className='rooms-title'>{langs[savedLanguage].title}</h1>
      <div className="services-container">
        {langs[savedLanguage].services.map((service) => (
          <div key={service.id} className="service-card">
            <img src={service.image} alt={service.title} className="service-image" />
            <h3 className="service-title">{service.title}</h3>
            <p className="service-description">{service.undertitle}</p>
            <a href={`/services/${service.id}`} className="service-button">{langs[savedLanguage].more_btn}</a>
          </div>
        ))}
      </div>
    </section>
  );
};

export default ServicesComponent;
